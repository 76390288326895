<template>
  <main>
    <section class="main-post section-padding">
      <div class="container with-pad">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="caption text-center">
              <h1 class="fz-55 mt-30">
                {{ blog.titulo }}
              </h1>
              <h3 class="fz-40 mt-30">
                {{ blog.subtitulo }}
              </h3>
              <p class="sub-title mt-15">
                {{ blog.created_at | momentRelativeTime }}
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="cont">
              <div class="text" v-html="blog.descripcion"></div>
              <div
                class="info-area flex mt-20 pb-20 pt-20 bord-thin-top bord-thin-bottom"
              >
                <!-- <div>
                <div class="tags flex">
                  <div class="valign">
                    <span>Tags :</span>
                  </div>
                  <div>
                    <a href="#">Tech</a>
                    <a href="#">Gavi</a>
                  </div>
                </div>
              </div> -->
                <div class="m-auto">
                  <div class="share-icon flex">
                    <div class="valign">
                      <span>Compartir :</span>
                    </div>
                    <div>
                      <a
                        href="http://facebook.com/sharer.php?u=http%3A%2F%2Fwebdesigninnovation.com%2F%3Fp%3D1263"
                        target="_blank"
                        ><i class="fab fa-facebook-f"></i
                      ></a>
                      <a
                        href="http://twitter.com/home?status=Botones+de+compartir+en+redes+sociales+personalizados+y+sin+javascript%20http%3A%2F%2Fwebdesigninnovation.com%2F%3Fp%3D1263%20v/%20@skotperez"
                        target="_blank"
                        ><i class="fab fa-twitter"></i
                      ></a>
                      <a href="https://www.youtube.com/" target="_blank"
                        ><i class="fab fa-youtube"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
  
  <script>
import moment from "moment";
export default {
  name: "BlogDetailsView",
  props: ["id"],
  data() {
    return {
      blog: false,
    };
  },
  created() {
    this.getBlog();
  },
  methods: {
    getBlog() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "blogs/detalle/" + this.id)
        .then((response) => {
          this.blog = response.data.datos;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
  },
  computed: {
    urlStorage() {
      return process.env.VUE_APP_URL_STORAGE;
    },
  },
  filters: {
    momentRelativeTime: function (date) {
      moment.locale("es");
      //return moment(date).format("MMMM Do YYYY, h:mm:ss a");
      return moment(date).fromNow();
    },
  },
};
</script>