import { render, staticRenderFns } from "./PoliticaPrivacidadView.vue?vue&type=template&id=468b0146"
import script from "./PoliticaPrivacidadView.vue?vue&type=script&lang=js"
export * from "./PoliticaPrivacidadView.vue?vue&type=script&lang=js"
import style0 from "./PoliticaPrivacidadView.vue?vue&type=style&index=0&id=468b0146&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports