<template>
  <div class="blog section-padding pb-70 sub-bg" data-scroll-index="5">
    <div class="container">
      <div class="sec-head mb-80 wow fadeIn">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <div class="d-inline-block">
              <div class="sub-title-icon d-flex align-items-center">
                <span class="icon pe-7s-rocket"></span>
                <h6>Sobre Nosotros</h6>
              </div>
            </div>
            <h3 class="mb-30">Nuestra Misión</h3>
            <p class="mt-15 mb-30">
              Nuestra misión es ser el socio tecnológico de confianza para cada
              comercio, empresa y persona, acompañándolos en su transformación
              digital. Nos esforzamos por ser más que simples proveedores de
              servicios; buscamos ser aliados estratégicos que empoderen a
              nuestros clientes a través de la tecnología. Comprometidos en
              comprender las necesidades de cada cliente, diseñamos soluciones
              web innovadoras que impulsan su crecimiento y éxito en un mundo
              digitalmente conectado. Además, nos comprometemos a aliviar la
              carga de mantener actualizados sus sitios web y soluciones
              digitales, ofreciendo servicios de gestión de contenido y soporte
              continuo. Así, les permitimos enfocarse en lo que realmente
              importa: hacer crecer su negocio.
            </p>

            <h3 class="mt-30 mb-30">Nuestra Visión</h3>
            <p class="mt-15">
              Para el 2030, aspiramos a ser reconocidos como líderes destacados
              en el ámbito de las soluciones tecnológicas, siendo referentes
              tanto a nivel local como global. Nos visualizamos como la opción
              preferida por comercios, empresas y personas que buscan
              transformar su presencia en línea y potenciar su éxito en el mundo
              digital. Nos esforzaremos continuamente por ofrecer innovación,
              calidad y excelencia en todos nuestros servicios, consolidando
              nuestra posición como el socio tecnológico de confianza para todos
              nuestros clientes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "NosotrosComponent",
};
</script>