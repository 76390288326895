<template>
  <main>
    <section class="main-post section-padding">
      <div class="container with-pad">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="caption text-center">
              <h1 class="fz-30 mt-30 mb-50">
                Política de Privacidad de Web Design Innovation
              </h1>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="cont">
              <div class="text" style="text-align: justify">
                <p class="mb-3">
                  <small>Última actualización: 8 de julio del 2024</small>
                </p>

                <p class="mb-3">
                  <strong>Web Design Innovation</strong> ("nosotros", "nuestro"
                  o "nos") opera el sitio web
                  <a href="https://webdesigninnovation.com/"
                    >webdesigninnovation.com</a
                  >
                  (en adelante, el "Servicio"). Esta página le informa sobre
                  nuestras políticas con respecto a la recopilación, el uso y la
                  divulgación de datos personales cuando utiliza nuestro
                  Servicio y las opciones que ha asociado con esos datos.
                  Utilizamos sus datos para proporcionar y mejorar el Servicio.
                  Al utilizar el Servicio, usted acepta la recopilación y el uso
                  de la información de acuerdo con esta política. A menos que se
                  defina lo contrario en esta Política de Privacidad, los
                  términos utilizados en esta Política de Privacidad tienen los
                  mismos significados que en nuestros Términos y Condiciones,
                  accesibles desde
                  <a
                    href="https://webdesigninnovation.com/terminos-y-condiciones"
                    >webdesigninnovation.com/terminos-y-condiciones</a
                  >.
                </p>

                <h2 class="fz-25 mt-30 mb-30">
                  Recopilación y uso de la información
                </h2>

                <p class="mb-3">
                  Recopilamos varios tipos diferentes de información con
                  diversas finalidades para proporcionar y mejorar nuestro
                  Servicio. Tipos de datos recopilados
                </p>

                <h2 class="fz-25 mt-30 mb-30">Datos personales</h2>

                <p class="mb-3">
                  Al utilizar nuestro Servicio, podemos pedirle que nos
                  proporcione cierta información de identificación personal que
                  se puede utilizar para contactarlo o identificarlo ("Datos
                  personales"). La información de identificación personal puede
                  incluir, pero no se limita a: Dirección de correo electrónico
                  Nombre y apellido Número de teléfono Dirección, País,
                  Departamento, Código postal, Ciudad Cookies y datos de uso
                </p>

                <h2 class="fz-25 mt-30 mb-30">Datos de uso</h2>

                <p class="mb-3">
                  También podemos recopilar información sobre cómo se accede y
                  utiliza el Servicio («Datos de uso»). Estos Datos de uso
                  pueden incluir información como la dirección del protocolo de
                  Internet de su ordenador (por ejemplo, dirección IP), tipo de
                  navegador, versión del navegador, las páginas de nuestro
                  Servicio que visita, la hora y la fecha de su visita, el
                  tiempo que pasa en esas páginas, identificadores únicos de
                  dispositivo y otros datos de diagnóstico.
                </p>

                <h2 class="fz-25 mt-30 mb-30">Seguridad de los datos</h2>

                <p class="mb-3">
                  La seguridad de sus datos es importante para nosotros, pero
                  recuerde que ningún método de transmisión por Internet o
                  método de almacenamiento electrónico es 100% seguro. Aunque
                  nos esforzamos por utilizar medios comercialmente aceptables
                  para proteger sus Datos personales, no podemos garantizar su
                  seguridad absoluta.
                </p>

                <h2 class="fz-25 mt-30 mb-30">Proveedores de servicios</h2>

                <p class="mb-3">
                  Podemos emplear empresas e individuos de terceros para
                  facilitar nuestro Servicio ("Proveedores de servicios"), para
                  proporcionar el Servicio en nuestro nombre, para realizar
                  servicios relacionados con el Servicio o para ayudarnos a
                  analizar cómo se utiliza nuestro Servicio. Estos terceros
                  tienen acceso a sus Datos personales solo para realizar estas
                  tareas en nuestro nombre y están obligados a no divulgarlos ni
                  utilizarlos para ningún otro fin.
                </p>

                <h2 class="fz-25 mt-30 mb-30">Enlaces a otros sitios</h2>

                <p class="mb-3">
                  Nuestro Servicio puede contener enlaces a otros sitios que no
                  son operados por nosotros. Si hace clic en un enlace de un
                  tercero, será dirigido al sitio de ese tercero. Le
                  recomendamos encarecidamente que revise la Política de
                  privacidad de cada sitio que visite. No tenemos control ni
                  asumimos responsabilidad alguna por el contenido, las
                  políticas de privacidad o las prácticas de sitios o servicios
                  de terceros.
                </p>

                <h2 class="fz-25 mt-30 mb-30">Facturación y Pagos</h2>

                <p class="mb-3">
                  Web Design Innovation recopila información de pago y
                  facturación para procesar los pagos relacionados con nuestros
                  servicios. Esto incluye el costo anual del dominio y cualquier
                  cargo adicional según se describe en nuestros Términos y
                  Condiciones.
                </p>

                <h2 class="fz-25 mt-30 mb-30">
                  Derechos del Usuario y Políticas de Cancelación
                </h2>

                <p class="mb-3">
                  Los usuarios tienen derecho a cancelar su servicio en
                  cualquier momento. Sin embargo, si la cancelación se produce
                  antes de transcurridos tres meses desde el inicio del
                  servicio, se aplicará un cargo de implementación de $300.000
                  pesos para cubrir los costos iniciales de configuración.
                </p>

                <p class="mb-3">
                  Si el cliente ha pagado por un plan anual y desea cancelar el
                  servicio antes de que culmine el año, se validará si aplica el
                  costo de implementación mencionado anteriormente. Se calculará
                  el tiempo de uso del servicio y se aplicará el costo mensual
                  correspondiente por dicho periodo. La diferencia entre el
                  monto pagado inicialmente por el plan anual y el costo mensual
                  acumulado será reintegrada al cliente.
                </p>

                <h2 class="fz-25 mt-30 mb-30">
                  Finalización del Servicio y Eliminación de Datos
                </h2>

                <ul class="list-style">
                  <li>
                    <p class="mb-3">
                      Al finalizar el servicio, ya sea por cancelación o no
                      renovación, se dará al cliente un plazo de 20 días para
                      descargar, exportar o solicitar su información. Después de
                      este periodo, toda la información y el acceso a la cuenta
                      del cliente serán borrados automáticamente.
                    </p>
                  </li>
                  <li>
                    <p class="mb-3">
                      Se ofrece la opción de pausar el servicio, lo cual
                      extiende el plazo para el borrado de la cuenta y los datos
                      hasta por 6 meses. Si el cliente decide retomar el
                      servicio dentro de este periodo, la información puede ser
                      restablecida.
                    </p>
                  </li>
                </ul>

                <h2 class="fz-25 mt-30 mb-30">
                  Propiedad de Datos, Archivos Multimedia y Dominio
                </h2>

                <ul class="list-style">
                  <li>
                    <p class="mb-3">
                      El cliente es el propietario de todos los datos y archivos
                      multimedia que cargue y almacene utilizando nuestros
                      servicios. Web Design Innovation no reclamará ningún
                      derecho de propiedad sobre dichos datos y archivos. El
                      cliente conserva todos los derechos y la responsabilidad
                      sobre el uso y la distribución de sus datos y archivos
                      multimedia.
                    </p>
                  </li>
                  <li>
                    <p class="mb-3">
                      El cliente será siempre el propietario del dominio
                      asociado al servicio. Web Design Innovation no reclamará
                      ningún derecho de propiedad sobre el dominio del cliente.
                      La gestión y el control del dominio permanecerán bajo la
                      titularidad del cliente en todo momento.
                    </p>
                  </li>
                </ul>

                <h2 class="fz-25 mt-30 mb-30">
                  Cambios a esta Política de privacidad
                </h2>

                <p class="mb-3">
                  Podemos actualizar nuestra Política de privacidad de vez en
                  cuando. Le notificaremos cualquier cambio publicando la nueva
                  Política de privacidad en esta página. Se le aconseja que
                  revise periódicamente esta Política de privacidad para
                  detectar cualquier cambio. Los cambios a esta Política de
                  privacidad son efectivos cuando se publican en esta página.
                </p>

                <h2 class="fz-25 mt-30 mb-30">Contáctenos</h2>

                <p class="mb-3">
                  Si tiene alguna pregunta sobre esta Política de privacidad,
                  contáctenos:
                </p>
                <p class="mb-3">
                  Por correo electrónico:
                  <strong
                    ><a href="mailto:info@webdesigninnovation.com"
                      >info@webdesigninnovation.com</a
                    ></strong
                  >
                </p>
                <p class="mb-3">
                  Visitando esta página en nuestro sitio web:
                  <strong
                    ><a href="https://webdesigninnovation.com/"
                      >webdesigninnovation.com</a
                    ></strong
                  >
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-30">
          <div class="col-md-5">
            <div class="contact mt-20">
              <div class="row">
                <div class="col">
                  <button type="button" @click="$router.push({ name: 'home' })">
                    <span class="text">Regresar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
        
    <script>
export default {
  name: "PoliticaPrivacidadView",
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {
    urlStorage() {
      return process.env.VUE_APP_URL_STORAGE;
    },
  },
};
</script>
<style>
.list-style li {
  list-style: initial;
}
</style>