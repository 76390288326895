import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VeeValidate from 'vee-validate';

import axios from 'axios'
import VueAxios from 'vue-axios'

import validateEs from './lang/veevalidate/es.js';
import validateEn from './lang/veevalidate/en.js';

Vue.config.productionTip = false

Vue.use(VueAxios, axios)

Vue.use(VeeValidate, {
  locale: 'es',
  dictionary: {
    es: {
      messages: validateEs
    },
    en: {
      messages: validateEn
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
