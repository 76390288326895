<template>
  <section class="contact section-padding" data-scroll-index="6">
    <div class="container">
      <div class="sec-head mb-80 wow fadeIn">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <div class="d-inline-block">
              <div class="sub-title-icon d-flex align-items-center">
                <span class="icon pe-7s-map-marker"></span>
                <h6>Contáctanos</h6>
              </div>
            </div>
            <h3>Resolveremos tus dudas!</h3>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-5 valign wow fadeIn" data-wow-delay=".2s">
          <div class="info full-width md-mb80">
            <div class="item mb-30 d-flex align-items-center">
              <div class="mr-15">
                <span class="icon fz-40 main-color pe-7s-call"></span>
              </div>
              <div class="mr-10">
                <h6 class="opacity-7">Teléfono</h6>
              </div>
              <div class="ml-auto">
                <h4>{{ empresa.telefono }}</h4>
              </div>
            </div>
            <div class="item mb-30 d-flex align-items-center">
              <div class="mr-15">
                <span class="icon fz-40 main-color pe-7s-mail"></span>
              </div>
              <div class="mr-10">
                <h6 class="opacity-7">E-mail</h6>
              </div>
              <div class="ml-auto">
                <h4>
                  <a :href="'mailto:' + empresa.email">{{ empresa.email }}</a>
                </h4>
              </div>
            </div>
            <div class="item d-flex align-items-center">
              <div class="mr-15">
                <span class="icon fz-40 main-color pe-7s-map-marker"></span>
              </div>
              <div class="mr-10">
                <h6 class="opacity-7">Dirección</h6>
              </div>
              <div class="ml-auto">
                <h4>{{ empresa.direccion }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 valign wow fadeIn" data-wow-delay=".4s">
          <div class="full-width">
            <form novalidate @submit.prevent="sendData">
              <div class="messages"></div>

              <div class="controls row">
                <div class="col-lg-6">
                  <div class="form-group mb-30">
                    <label>Tu Nombre</label>
                    <input
                      type="text"
                      name="name"
                      v-model="nombre"
                      v-validate="'required'"
                      data-vv-as="Tu nombre"
                    />
                    <span class="text-danger">{{ errors.first("name") }}</span>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-30">
                    <label>Tu E-mail</label>
                    <input
                      type="email"
                      name="email"
                      v-model="email"
                      v-validate="'required'"
                      data-vv-as="Tu e-mail"
                    />
                    <span class="text-danger">{{ errors.first("email") }}</span>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>Tu Mensaje</label>
                    <textarea
                      name="message"
                      v-model="mensaje"
                      v-validate="'required'"
                      data-vv-as="Tu mensaje"
                    ></textarea>
                    <span class="text-danger">{{
                      errors.first("message")
                    }}</span>
                  </div>
                  <div class="mt-30">
                    <button type="button" @click="validaRecaptcha()">
                      <span class="text">Enviar mensaje</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "ContactoComponent",
  data() {
    return {
      nombre: "",
      email: "",
      mensaje: "",
    };
  },
  methods: {
    validaRecaptcha() {
      var that = this;
      grecaptcha.ready(function () {
        grecaptcha
          .execute("6Ldd2XwpAAAAAPibtnf9CBmN6A-j1vpXd60NOolj", {
            action: "submit",
          })
          .then(function (token) {
            that.sendData(token);
          });
      });
    },
    sendData(token) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.axios
            .post(process.env.VUE_APP_API_URL + "contacto/store", {
              nombre: this.nombre,
              email: this.email,
              mensaje: this.mensaje,
              idEmpresa: this.empresa.idEmpresa,
              tokenRecaptcha: token,
            })
            .then((response) => {
              if (!response.data.error) {
                Swal.fire({
                  title: "Genial!",
                  text: "Contácto enviado exitosamente!",
                  icon: "success",
                });

                this.nombre = "";
                this.email = "";
                this.mensaje = "";
              } else {
                Swal.fire({
                  title: "Error!",
                  text: response.data.error,
                  icon: "error",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "Error!",
                text: "Oops error inesperado",
                icon: "error",
              });
            });
        } else {
          Swal.fire({
            title: "Error!",
            text: "Hay errores en el formulario",
            icon: "error",
          });
        }
      });
    },
  },
  computed: {
    empresa() {
      return this.$store.getters.getEmpresa;
    },
  },
};
</script>