import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    empresa: []
  },
  getters: {
    getEmpresa(state) {
      return state.empresa;
    }
  },
  mutations: {
    setEmpresa(state, data) {
      state.empresa = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
