<template>
  <nav class="navbar navbar-chang navbar-expand-lg">
    <div class="container position-re">
      <div class="row">
        <div class="col-lg-2 col-6 order1">
          <!-- Logo -->
          <router-link
            :to="{
              name: 'home',
            }"
            class="logo"
            data-scroll-nav="0"
          >
            <img src="@/assets/logo.png" alt="Logo WDI" />
          </router-link>
        </div>
        <div class="col-lg-7 order3">
          <div class="bg">
            <!-- navbar links -->
            <div class="full-width">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <router-link
                    :to="{
                      name: 'home',
                    }"
                    class="nav-link"
                    data-scroll-nav="0"
                    ><span class="rolling-text">Home</span></router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{
                      name: 'home',
                    }"
                    class="nav-link"
                    data-scroll-nav="1"
                    ><span class="rolling-text">Módulos</span></router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{
                      name: 'home',
                    }"
                    class="nav-link"
                    data-scroll-nav="2"
                    ><span class="rolling-text">Planes</span></router-link
                  >
                </li>
                <!-- <li class="nav-item">
                  <router-link
                    :to="{
                      name: 'home',
                    }"
                    class="nav-link"
                    data-scroll-nav="3"
                    ><span class="rolling-text">Testimonios</span></router-link
                  >
                </li> -->
                <li class="nav-item">
                  <router-link
                    :to="{
                      name: 'home',
                    }"
                    class="nav-link"
                    data-scroll-nav="4"
                    ><span class="rolling-text">Blog</span></router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{
                      name: 'home',
                    }"
                    class="nav-link"
                    data-scroll-nav="5"
                    ><span class="rolling-text">Nosotros</span></router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{
                      name: 'home',
                    }"
                    class="nav-link"
                    data-scroll-nav="6"
                    ><span class="rolling-text">Contactanos</span></router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-6 order2">
          <div class="bord d-flex justify-content-end">
            <button
              type="button"
              class="btn-login ml-5"
              data-bs-toggle="modal"
              data-bs-target="#accesoModal"
            >
              <span class="text">Acceso</span>
            </button>
            <!-- <button type="button" class="btn-login ml-5">
              <span class="text">Registro</span>
            </button> -->
            <button class="navbar-toggler" type="button">
              <span class="icon-bar"><i class="fas fa-bars"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "HeaderBase",
  data() {
    return {};
  },
  computed: {
    empresa() {
      return this.$store.getters.getEmpresa;
    },
  },
};
</script>