<template>
  <div class="blog section-padding pb-70 sub-bg" data-scroll-index="4">
    <div class="container">
      <div class="sec-head text-center mb-80 wow fadeIn">
        <div class="d-inline-block">
          <div class="sub-title-icon d-flex align-items-center">
            <span class="icon pe-7s-note"></span>
            <h6>Nuestros Blogs</h6>
          </div>
        </div>
        <h3>Tal vez te puedan interesar estos temas</h3>
      </div>
      <div v-if="blogs.length > 0" class="row justify-content-center">
        <div
          v-for="(blog, index) in blogs"
          :key="index"
          class="col-lg-4 col-md-6 wow fadeIn"
          :data-wow-delay="'.' + (index + 1) * 2 + 's'"
        >
          <div class="item mb-50 bord">
            <div class="img">
              <img
                :src="urlStorage + blog.imagenPrincipal"
                :alt="blog.titulo"
              />
            </div>
            <div class="cont">
              <span class="date mb-10">{{
                blog.created_at | momentRelativeTime
              }}</span>
              <h4>
                <router-link
                  :to="{
                    name: 'blogDetails',
                    params: { id: blog.idNoticiaBlog },
                  }"
                  >{{ blog.titulo }}</router-link
                >
              </h4>
              <router-link
                :to="{
                  name: 'blogDetails',
                  params: { id: blog.idNoticiaBlog },
                }"
                class="mt-30 underline sub-title ls1"
                >Leer más</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BlogsComponent",
  data() {
    return {
      blogs: [],
    };
  },
  created() {
    this.getBlogs();
  },
  methods: {
    getBlogs() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "blogs/get")
        .then((response) => {
          this.blogs = response.data.datos;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
  },
  computed: {
    urlStorage() {
      return process.env.VUE_APP_URL_STORAGE;
    },
  },
  filters: {
    momentRelativeTime: function (date) {
      moment.locale("es");
      //return moment(date).format("MMMM Do YYYY, h:mm:ss a");
      return moment(date).fromNow();
    },
  },
};
</script>