var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog section-padding pb-70 sub-bg",attrs:{"data-scroll-index":"4"}},[_c('div',{staticClass:"container"},[_vm._m(0),(_vm.blogs.length > 0)?_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.blogs),function(blog,index){return _c('div',{key:index,staticClass:"col-lg-4 col-md-6 wow fadeIn",attrs:{"data-wow-delay":'.' + (index + 1) * 2 + 's'}},[_c('div',{staticClass:"item mb-50 bord"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.urlStorage + blog.imagenPrincipal,"alt":blog.titulo}})]),_c('div',{staticClass:"cont"},[_c('span',{staticClass:"date mb-10"},[_vm._v(_vm._s(_vm._f("momentRelativeTime")(blog.created_at)))]),_c('h4',[_c('router-link',{attrs:{"to":{
                  name: 'blogDetails',
                  params: { id: blog.idNoticiaBlog },
                }}},[_vm._v(_vm._s(blog.titulo))])],1),_c('router-link',{staticClass:"mt-30 underline sub-title ls1",attrs:{"to":{
                name: 'blogDetails',
                params: { id: blog.idNoticiaBlog },
              }}},[_vm._v("Leer más")])],1)])])}),0):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sec-head text-center mb-80 wow fadeIn"},[_c('div',{staticClass:"d-inline-block"},[_c('div',{staticClass:"sub-title-icon d-flex align-items-center"},[_c('span',{staticClass:"icon pe-7s-note"}),_c('h6',[_vm._v("Nuestros Blogs")])])]),_c('h3',[_vm._v("Tal vez te puedan interesar estos temas")])])
}]

export { render, staticRenderFns }