<template>
  <main>
    <SliderBase></SliderBase>
    <ModulosComponent></ModulosComponent>
    <PlanesComponent></PlanesComponent>
    <!-- <TestimoniosComponent></TestimoniosComponent> -->
    <BlogsComponent></BlogsComponent>
    <NosotrosComponent></NosotrosComponent>
    <ContactoComponent></ContactoComponent>
  </main>
</template>

<script>
// @ is an alias to /src
import SliderBase from "@/components/base/SliderBase.vue";
import ModulosComponent from "@/components/ModulosComponent.vue";
import TestimoniosComponent from "@/components/TestimoniosComponent.vue";
import PlanesComponent from "@/components/PlanesComponent.vue";
import BlogsComponent from "@/components/BlogsComponent.vue";
import NosotrosComponent from "@/components/NosotrosComponent.vue";
import ContactoComponent from "@/components/ContactoComponent.vue";

export default {
  name: "HomeView",
  components: {
    SliderBase,
    ModulosComponent,
    TestimoniosComponent,
    PlanesComponent,
    BlogsComponent,
    NosotrosComponent,
    ContactoComponent,
  },
};
</script>
