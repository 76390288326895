var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"header-crev bord-thin-bottom",attrs:{"data-scroll-index":"0"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 valign"},[_c('div',{staticClass:"content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"mt-50"},[_c('router-link',{staticClass:"butn butn-md butn-bg bg-white text-dark radius-5",attrs:{"to":{
                name: 'home',
              },"data-scroll-nav":"2"}},[_c('span',[_vm._v("Ver planes")])]),_c('router-link',{staticClass:"butn butn-md butn-bord radius-5 ml-15",attrs:{"to":{
                name: 'home',
              },"data-scroll-nav":"5"}},[_c('span',[_vm._v("Contactanos")])])],1)])]),_vm._m(2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v(" Controla, organiza "),_c('br'),_c('span',{staticClass:"main-color"},[_vm._v("y crece")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text mt-30"},[_c('p',[_vm._v(" Nuestro software abarca todo lo que necesitas: ventas, inventarios, informes, pagos y más. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"img-author"},[_c('img',{attrs:{"src":"imgs/slider1.png","alt":""}})])])
}]

export { render, staticRenderFns }