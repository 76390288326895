<template>
  <section class="price section-padding pt-0" data-scroll-index="2">
    <div class="container">
      <div class="sec-head mb-80 wow fadeIn">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <div class="d-inline-block">
              <div class="sub-title-icon d-flex align-items-center">
                <span class="icon pe-7s-rocket"></span>
                <h6>Nuestros planes</h6>
              </div>
            </div>
            <h3>¡Grandes beneficios a un precio comodo!</h3>
            <p class="mt-15">
              Todos nuestros planes incluyen acompañamiento técnico, olvidate de
              la gestión de tu sitio web, nosotros lo hacemos por ti.
            </p>
          </div>
        </div>
      </div>
      <div v-if="planes.length > 0" class="row">
        <div
          v-for="(plan, index) in planes"
          :key="index"
          class="col-lg-6 wow fadeIn"
          :data-wow-delay="'.' + (index + 1) * 2 + 's'"
        >
          <div class="item md-mb50">
            <h6 class="type">{{ plan.nombre }}</h6>
            <div class="content d-flex align-items-center">
              <div class="amount mr-40">
                <h5
                  v-for="(precioPlan, indexPrecio) in plan.precio"
                  class="main-color"
                  :key="indexPrecio"
                >
                  ${{ formatPrecio(precioPlan.precio) }}
                  <small>/ {{ precioPlan.tiempoPlanControl }}</small>
                </h5>
                <router-link
                  :to="{
                    name: 'checkout',
                    params: { id: plan.idPlan },
                  }"
                  class="butn butn-md butn-bord radius-30 text-u text-center mt-30"
                >
                  <span>Adquirir</span>
                </router-link>
              </div>
              <div class="feat" v-html="plan.caracteristicas"></div>
            </div>
            <p class="text-center mt-3" v-html="plan.introduccion"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PlanesComponent",
  data() {
    return {
      planes: [],
    };
  },
  created() {
    this.getPlanes();
  },
  methods: {
    getPlanes() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "planes/get")
        .then((response) => {
          this.planes = response.data.datos;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    formatPrecio(precio) {
      return new Intl.NumberFormat().format(precio);
    },
  },
  computed: {
    urlStorage() {
      return process.env.VUE_APP_URL_STORAGE;
    },
  },
};
</script>