<template>
  <section class="testimonials section-padding pt-0" data-scroll-index="3">
    <div class="container">
      <div class="sec-head mb-80 wow fadeIn">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <div class="d-inline-block">
              <div class="sub-title-icon d-flex align-items-center">
                <span class="icon pe-7s-chat"></span>
                <h6>Testimonials</h6>
              </div>
            </div>
            <h3>Here’s what my clients say</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid wow fadeIn">
      <div
        class="testim-swiper"
        data-carousel="swiper"
        data-item="3"
        data-space="20"
      >
        <div
          id="content-carousel-container-unq-testim"
          class="swiper-container"
          data-swiper="container"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="item">
                <div>
                  <div class="cont mb-30">
                    <div class="d-flex align-items-center">
                      <div class="rate-stars fz-12">
                        <span class="rate main-color">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="fz-12 opacity-7 ml-10">(71 Reviews)</span>
                      </div>
                    </div>
                    <p class="mt-15">
                      We have purchased well into the thousands of items, but
                      this is without doubt one of the best we’ve have been
                      lucky enough to work on, the attention to detail apparent
                      throughout, and the delivery is impressively intuitive.
                    </p>
                  </div>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="img">
                        <img src="imgs/1.jpg" alt="" />
                      </div>
                    </div>
                    <div class="ml-30">
                      <div class="info">
                        <h6 class="main-color">Leonard Heiser</h6>
                        <span class="fz-13 mt-10 opacity-8"
                          >Envato customer</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="item">
                <div>
                  <div class="cont mb-30">
                    <div class="d-flex align-items-center">
                      <div class="rate-stars fz-12">
                        <span class="rate main-color">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="fz-12 opacity-7 ml-10">(71 Reviews)</span>
                      </div>
                    </div>
                    <p class="mt-15">
                      We have purchased well into the thousands of items, but
                      this is without doubt one of the best we’ve have been
                      lucky enough to work on, the attention to detail apparent
                      throughout, and the delivery is impressively intuitive.
                    </p>
                  </div>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="img">
                        <img src="imgs/2.jpg" alt="" />
                      </div>
                    </div>
                    <div class="ml-30">
                      <div class="info">
                        <h6 class="main-color">Leonard Heiser</h6>
                        <span class="fz-13 mt-10 opacity-8"
                          >Envato customer</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="item">
                <div>
                  <div class="cont mb-30">
                    <div class="d-flex align-items-center">
                      <div class="rate-stars fz-12">
                        <span class="rate main-color">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="fz-12 opacity-7 ml-10">(71 Reviews)</span>
                      </div>
                    </div>
                    <p class="mt-15">
                      We have purchased well into the thousands of items, but
                      this is without doubt one of the best we’ve have been
                      lucky enough to work on, the attention to detail apparent
                      throughout, and the delivery is impressively intuitive.
                    </p>
                  </div>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="img">
                        <img src="imgs/3.jpg" alt="" />
                      </div>
                    </div>
                    <div class="ml-30">
                      <div class="info">
                        <h6 class="main-color">Leonard Heiser</h6>
                        <span class="fz-13 mt-10 opacity-8"
                          >Envato customer</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="item">
                <div>
                  <div class="cont mb-30">
                    <div class="d-flex align-items-center">
                      <div class="rate-stars fz-12">
                        <span class="rate main-color">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="fz-12 opacity-7 ml-10">(71 Reviews)</span>
                      </div>
                    </div>
                    <p class="mt-15">
                      We have purchased well into the thousands of items, but
                      this is without doubt one of the best we’ve have been
                      lucky enough to work on, the attention to detail apparent
                      throughout, and the delivery is impressively intuitive.
                    </p>
                  </div>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="img">
                        <img src="imgs/2.jpg" alt="" />
                      </div>
                    </div>
                    <div class="ml-30">
                      <div class="info">
                        <h6 class="main-color">Leonard Heiser</h6>
                        <span class="fz-13 mt-10 opacity-8"
                          >Envato customer</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="item">
                <div>
                  <div class="cont mb-30">
                    <div class="d-flex align-items-center">
                      <div class="rate-stars fz-12">
                        <span class="rate main-color">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="fz-12 opacity-7 ml-10">(71 Reviews)</span>
                      </div>
                    </div>
                    <p class="mt-15">
                      We have purchased well into the thousands of items, but
                      this is without doubt one of the best we’ve have been
                      lucky enough to work on, the attention to detail apparent
                      throughout, and the delivery is impressively intuitive.
                    </p>
                  </div>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="img">
                        <img src="imgs/3.jpg" alt="" />
                      </div>
                    </div>
                    <div class="ml-30">
                      <div class="info">
                        <h6 class="main-color">Leonard Heiser</h6>
                        <span class="fz-13 mt-10 opacity-8"
                          >Envato customer</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TestimoniosComponent",
};
</script>