<template>
  <section class="services serv2 section-padding" data-scroll-index="1">
    <div v-if="categorias.length > 0" class="container">
      <div class="sec-head mb-80 wow fadeIn">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <div class="d-inline-block">
              <div class="sub-title-icon d-flex align-items-center">
                <span class="icon pe-7s-bell"></span>
                <h6>Nuestros Módulos</h6>
              </div>
            </div>
            <h3>¡Todo lo que necesitas en un solo lugar!</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-for="(categoria, index) in categorias"
          :key="index"
          class="col-lg-4 col-md-6 wow fadeIn"
          :data-wow-delay="'.' + (index + 1) * 2 + 's'"
        >
          <div class="item mb-30">
            <div class="cont">
              <div class="d-flex align-items-center mb-30">
                <div>
                  <span class="icon-img-50 mr-40">
                    <img
                      :src="urlStorage + categoria.imagen"
                      :alt="categoria.nombre"
                    />
                  </span>
                </div>
                <div>
                  <h5 class="fz-20">{{ categoria.nombre }}</h5>
                </div>
              </div>
              <div v-html="categoria.descripcion"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ModulosComponent",
  data() {
    return {
      categorias: [],
    };
  },
  created() {
    this.getCategorias();
  },
  methods: {
    getCategorias() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "categorias/get")
        .then((response) => {
          this.categorias = response.data.datos;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
  },
  computed: {
    urlStorage() {
      return process.env.VUE_APP_URL_STORAGE;
    },
  },
};
</script>