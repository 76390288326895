import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BlogDetailsView from '../views/BlogDetailsView.vue'
import CheckoutView from '../views/CheckoutView.vue'
import CheckoutResponseView from '../views/CheckoutResponseView.vue'
import PoliticaPrivacidad from '../views/PoliticaPrivacidadView.vue'
import TerminosCondiciones from '../views/TerminosCondicionesView.vue'
import Error404View from '../views/error/404View.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/blogs/detalle/:id',
    props: true,
    name: 'blogDetails',
    component: BlogDetailsView
  },
  {
    path: '/plan/checkout/response',
    name: 'checkoutResponse',
    component: CheckoutResponseView
  },
  {
    path: '/plan/checkout/:id',
    props: true,
    name: 'checkout',
    component: CheckoutView
  },
  {
    path: '*',
    name: 'error404',
    component: Error404View
  },
  {
    path: '/politica-de-privacidad',
    name: 'politicaPrivacidad',
    component: PoliticaPrivacidad
  },
  {
    path: '/terminos-y-condiciones',
    name: 'terminosCondiciones',
    component: TerminosCondiciones
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
