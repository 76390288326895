<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="accesoModal"
    tabindex="-1"
    aria-labelledby="accesoModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="accesoModalLabel">Acceso</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-lg-6 text-center">
                <div class="form-group">
                  <label>NIT: </label>
                  <input
                    type="text"
                    name="nit"
                    v-model="nit"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn-modal" @click="continuarAcceso()">
            Continuar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Swal from "sweetalert2";
export default {
  name: "ModalAccesoComponent",
  data() {
    return {
      nit: "",
    };
  },
  methods: {
    continuarAcceso() {
      if (this.nit != "") {
        this.axios
          .post(process.env.VUE_APP_API_URL + "acceso/validacion", {
            nit: this.nit,
          })
          .then((response) => {
            if (!response.data.error) {
              location.href = response.data.datos;
            } else {
              Swal.fire({
                title: "Error!",
                text: response.data.error,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error!",
              text: "Oops error inesperado",
              icon: "error",
            });
          });
      } else {
        Swal.fire({
          title: "Que Mal!",
          text: "Por favor ingrese el NIT",
          icon: "error",
        });
      }
    },
  },
  computed: {},
};
</script>
<style>
.modal .modal-dialog {
  color: #000;
}
.modal input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: transparent;
  padding: 15px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.modal .btn-modal {
  position: relative;
  float: right;
  padding: 10px 20px;
  color: #1d1d1d;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  background: #28e98c;
  border-radius: 30px;
  border: none;
}
.modal .btn-modal:hover {
  background: #1d1d1d;
  color: #28e98c;
}
</style>