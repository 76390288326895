<template>
  <div id="app">
    <HeaderBase></HeaderBase>
    <router-view></router-view>
    <FooterBase></FooterBase>
    <ModalAcceso></ModalAcceso>
  </div>
</template>
<script>
// @ is an alias to /src
import HeaderBase from "@/components/base/HeaderBase.vue";
import FooterBase from "@/components/base/FooterBase.vue";
import ModalAcceso from "@/components/ModalAccesoComponent.vue";

export default {
  name: "App",
  components: {
    HeaderBase,
    FooterBase,
    ModalAcceso,
  },
  data() {
    return {};
  },
  created() {
    this.getEmpresa();
  },
  methods: {
    getEmpresa() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "configuracion/empresa")
        .then((response) => {
          this.$store.commit("setEmpresa", response.data.datos);
        });
    },
  },
};
</script>