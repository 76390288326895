<template>
  <main>
    <section class="main-post section-padding">
      <div class="container with-pad">
        <div class="row justify-content-center">
          <div class="price col-md-5" id="price">
            <div class="col-lg-12 text-center mb-50">
              <h3 class="fz-30 mt-30">Respuestra de la Transacción</h3>
            </div>

            <div class="payment-resume item md-mb50">
              <div v-if="(codResponse = 1)" class="text-center text-success">
                <i class="fas fa-check"></i>
                <h4 class="">{{ title }}</h4>
              </div>
              <div
                v-else-if="(codResponse = 2)"
                class="text-center text-danger"
              >
                <i class="fas fa-times"></i>
                <h4 class="">{{ title }}</h4>
              </div>
              <div v-else-if="(codResponse = 3)" class="text-center text-info">
                <i class="fas fa-hourglass-start"></i>
                <h4 class="">{{ title }}</h4>
              </div>
              <div
                v-else-if="(codResponse = 4)"
                class="text-center text-warning"
              >
                <i class="fas fa-exclamation"></i>
                <h4 class="">{{ title }}</h4>
              </div>

              <div class="content mt-50">
                <div class="row my-2">
                  <div class="col-auto">N&deg; de referencia</div>
                  <div class="col text-right">{{ referencia }}</div>
                </div>
                <div class="row my-2">
                  <div class="col-auto">Fecha</div>
                  <div class="col text-right">{{ fecha }}</div>
                </div>
                <div class="row my-2">
                  <div class="col-auto">Banco</div>
                  <div class="col text-right">{{ banco }}</div>
                </div>
                <div class="row my-2">
                  <div class="col-auto">N&deg; del recibo</div>
                  <div class="col text-right">{{ recibo }}</div>
                </div>
                <div v-if="codResponse != 1" class="row my-2">
                  <div class="col-md-12">
                    <strong>Motivo: {{ motivo }}</strong>
                  </div>
                </div>
                <div class="row border-top pt-3 mt-20">
                  <div class="col-auto"><h6>Total</h6></div>
                  <div class="col main-color text-right">
                    <h6>${{ formatPrecio(total) + " " + moneda }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-30">
          <div class="col-md-5">
            <div class="contact mt-20">
              <div class="row">
                <!-- <div class="col">
                  <button type="button" @click="$route.push({ name: 'home' })">
                    <span class="text">Imprimir</span>
                  </button>
                </div> -->
                <div class="col">
                  <button type="button" @click="$router.push({ name: 'home' })">
                    <span class="text">Regresar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
      
  <script>
import Swal from "sweetalert2";
export default {
  name: "CheckoutResponseView",
  data() {
    return {
      ref_payco: "",
      title: "...",
      codResponse: 0,
      fecha: "",
      respuesta: "",
      referencia: "",
      motivo: "",
      recibo: "",
      banco: "",
      autorizacion: "",
      total: "",
      moneda: "",
    };
  },
  created() {
    this.ref_payco = this.getQueryParam("ref_payco");
    this.getResponse();
  },
  methods: {
    getResponse() {
      this.axios
        .get(
          "https://secure.epayco.co/validation/v1/reference/" + this.ref_payco
        )
        .then((response) => {
          if (response.data.success) {
            if (response.data.data.x_cod_response == 1) {
              this.title = "Pago Aprobado!";
            }
            //Transaccion Rechazada
            if (response.data.data.x_cod_response == 2) {
              this.title = "Pago Rechazado!";
            }
            //Transaccion Pendiente
            if (response.data.data.x_cod_response == 3) {
              this.title = "Pago Pendiente!";
            }
            //Transaccion Fallida
            if (response.data.data.x_cod_response == 4) {
              this.title = "Pago Fallido!";
            }

            this.codResponse = response.data.data.x_cod_response;

            this.fecha = response.data.data.x_transaction_date;
            this.respuesta = response.data.data.x_response;
            this.referencia = response.data.data.x_id_invoice;
            this.motivo = response.data.data.x_response_reason_text;
            this.recibo = response.data.data.x_transaction_id;
            this.banco = response.data.data.x_bank_name;
            this.autorizacion = response.data.data.x_approval_code;
            this.total = response.data.data.x_amount;
            this.moneda = response.data.data.x_currency_code;
          } else {
            Swal.fire({
              title: "Error!",
              text: "Error consultando la información",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "Oops error inesperado",
            icon: "error",
          });
        });
    },
    getQueryParam(param) {
      location.search
        .substr(1)
        .split("&")
        .some(function (item) {
          // returns first occurence and stops
          return item.split("=")[0] == param && (param = item.split("=")[1]);
        });
      return param;
    },
    formatPrecio(precio) {
      if (precio >= 0) {
        return new Intl.NumberFormat().format(precio);
      } else {
        return " -";
      }
    },
  },
  computed: {
    urlStorage() {
      return process.env.VUE_APP_URL_STORAGE;
    },
  },
};
</script>