<template>
  <main>
    <section class="main-post section-padding">
      <div class="container with-pad">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="caption text-center">
              <h1 class="fz-30 mt-30 mb-50">
                Términos y Condiciones de Web Design Innovation
              </h1>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="cont">
              <div class="text" style="text-align: justify">
                <p class="mb-3">
                  <small>Última actualización: 8 de julio del 2024</small>
                </p>

                <p class="mb-3">
                  Por favor, lea estos Términos y Condiciones ("Términos",
                  "Términos y Condiciones") cuidadosamente antes de usar el
                  sitio web
                  <a href="https://webdesigninnovation.com/"
                    >webdesigninnovation.com</a
                  >
                  (en adelante, el "Servicio") operado por
                  <strong>Web Design Innovation</strong> ("nosotros", "nuestro"
                  o "nos").
                </p>

                <p class="mb-3">
                  Su acceso y uso del Servicio están condicionados a su
                  aceptación y cumplimiento de estos Términos. Estos Términos se
                  aplican a todos los visitantes, usuarios y otras personas que
                  acceden o utilizan el Servicio.
                </p>

                <p class="mb-3">
                  Al acceder o utilizar el Servicio, usted acepta estar sujeto a
                  estos Términos. Si no está de acuerdo con alguna parte de los
                  términos, entonces no tiene permiso para acceder al Servicio.
                </p>

                <h2 class="fz-25 mt-30 mb-30">Contenido</h2>

                <p class="mb-3">
                  Nuestro Servicio le permite publicar, enlazar, almacenar,
                  compartir y obtener acceso a información, texto, gráficos,
                  videos u otros materiales ("Contenido"). Usted es responsable
                  del Contenido que publica en o a través del Servicio, incluida
                  su legalidad, fiabilidad y adecuación.
                </p>

                <p class="mb-3">
                  Al publicar Contenido en o a través del Servicio, usted
                  declara y garantiza que: (i) el Contenido es suyo (usted es el
                  propietario) o tiene el derecho de usarlo y otorgarnos los
                  derechos y licencias según lo dispuesto en estos Términos, y
                  (ii) la publicación de su Contenido en o a través del Servicio
                  no viola los derechos de privacidad, derechos de publicidad,
                  derechos de autor, derechos contractuales u otros derechos de
                  cualquier persona.
                </p>

                <h2 class="fz-25 mt-30 mb-30">Enlaces a otros sitios web</h2>

                <p class="mb-3">
                  Nuestro Servicio puede contener enlaces a sitios web de
                  terceros o servicios que no son propiedad ni están controlados
                  por <strong>Web Design Innovation</strong>.
                </p>

                <p class="mb-3">
                  Web Design Innovation no tiene control ni asume
                  responsabilidad alguna por el contenido, las políticas de
                  privacidad o las prácticas de sitios o servicios de terceros.
                  Además, usted reconoce y acepta que Web Design Innovation no
                  será responsable, directa o indirectamente, de ningún daño o
                  pérdida causada o supuestamente causada por o en conexión con
                  el uso de o la confianza en dicho contenido, bienes o
                  servicios disponibles en o a través de tales sitios o
                  servicios.
                </p>

                <p class="mb-3">
                  Le recomendamos encarecidamente que revise los términos y
                  condiciones y las políticas de privacidad de cualquier sitio
                  web de terceros o servicios que visite.
                </p>

                <h2 class="fz-25 mt-30 mb-30">Facturación y Pagos</h2>

                <h3 class="fz-20 mt-30 mb-30">
                  Costos de Dominio y Servicios Mensuales
                </h3>
                <p class="mb-3">
                  Al adquirir un servicio mensual con Web Design Innovation, el
                  cliente se compromete a cubrir el costo anual del dominio
                  asociado al servicio. Este costo será facturado por adelantado
                  junto con el primer pago mensual del servicio.
                </p>

                <h3 class="fz-20 mt-30 mb-30">Política de Cancelación</h3>

                <ul class="list-style">
                  <li>
                    <p class="mb-3">
                      En caso de que el cliente desee cancelar el servicio y no
                      hayan transcurrido al menos tres meses desde el inicio del
                      mismo, el cliente deberá cubrir un costo de implementación
                      equivalente a $300.000 pesos. Este cargo se aplicará para
                      compensar los recursos y el tiempo invertido en la
                      configuración inicial del servicio.
                    </p>
                  </li>
                  <li>
                    <p class="mb-3">
                      Si el cliente ha pagado por un plan anual y desea cancelar
                      el servicio antes de que culmine el año, se validará si
                      aplica el costo de implementación mencionado
                      anteriormente. Se calculará el tiempo de uso del servicio
                      y se aplicará el costo mensual correspondiente por dicho
                      periodo. La diferencia entre el monto pagado inicialmente
                      por el plan anual y el costo mensual acumulado será
                      reintegrada al cliente.
                    </p>
                  </li>
                </ul>

                <h3 class="fz-20 mt-30 mb-30">
                  Finalización del Servicio y Eliminación de Datos
                </h3>

                <ul class="list-style">
                  <li>
                    <p class="mb-3">
                      Al finalizar el servicio, ya sea por cancelación o no
                      renovación, se dará al cliente un plazo de 20 días para
                      descargar, exportar o solicitar su información. Después de
                      este periodo, toda la información y el acceso a la cuenta
                      del cliente serán borrados automáticamente.
                    </p>
                    <p class="mb-3">
                      Se ofrece la opción de pausar el servicio, lo cual
                      extiende el plazo para el borrado de la cuenta y los datos
                      hasta por 6 meses. Si el cliente decide retomar el
                      servicio dentro de este periodo, la información puede ser
                      restablecida.
                    </p>
                  </li>
                </ul>

                <h2 class="fz-25 mt-30 mb-30">
                  Propiedad de Datos, Archivos Multimedia y Dominio
                </h2>

                <ul class="list-style">
                  <li>
                    <p class="mb-3">
                      El cliente es el propietario de todos los datos y archivos
                      multimedia que cargue y almacene utilizando nuestros
                      servicios. Web Design Innovation no reclamará ningún
                      derecho de propiedad sobre dichos datos y archivos. El
                      cliente conserva todos los derechos y la responsabilidad
                      sobre el uso y la distribución de sus datos y archivos
                      multimedia.
                    </p>
                  </li>
                  <li>
                    <p class="mb-3">
                      El cliente será siempre el propietario del dominio
                      asociado al servicio. Web Design Innovation no reclamará
                      ningún derecho de propiedad sobre el dominio del cliente.
                      La gestión y el control del dominio permanecerán bajo la
                      titularidad del cliente en todo momento.
                    </p>
                  </li>
                </ul>

                <h2 class="fz-25 mt-30 mb-30">Cambios</h2>

                <p class="mb-3">
                  Nos reservamos el derecho, a nuestra sola discreción, de
                  modificar o reemplazar estos Términos en cualquier momento. Si
                  una revisión es importante, intentaremos proporcionar un aviso
                  con al menos 30 días de anticipación antes de que entren en
                  vigencia los nuevos términos. Lo que constituye un cambio
                  importante se determinará a nuestra sola discreción.
                </p>

                <p class="mb-3">
                  Al continuar accediendo o utilizando nuestro Servicio después
                  de que esas revisiones entren en vigencia, usted acepta estar
                  sujeto a los términos revisados. Si no está de acuerdo con los
                  nuevos términos, por favor deje de usar el Servicio.
                </p>

                <h2 class="fz-25 mt-30 mb-30">Contáctenos</h2>

                <p class="mb-3">
                  Si tiene alguna pregunta sobre esta Política de privacidad,
                  contáctenos:
                </p>
                <p class="mb-3">
                  Por correo electrónico:
                  <strong
                    ><a href="mailto:info@webdesigninnovation.com"
                      >info@webdesigninnovation.com</a
                    ></strong
                  >
                </p>
                <p class="mb-3">
                  Visitando esta página en nuestro sitio web:
                  <strong
                    ><a href="https://webdesigninnovation.com/"
                      >webdesigninnovation.com</a
                    ></strong
                  >
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-30">
          <div class="col-md-5">
            <div class="contact mt-20">
              <div class="row">
                <div class="col">
                  <button type="button" @click="$router.push({ name: 'home' })">
                    <span class="text">Regresar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
          
      <script>
export default {
  name: "PoliticaPrivacidadView",
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {
    urlStorage() {
      return process.env.VUE_APP_URL_STORAGE;
    },
  },
};
</script>
<style>
.list-style li {
  list-style: initial;
}
</style>