<template>
  <main>
    <section class="main-post section-padding">
      <div class="container with-pad">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="caption text-center">
              <h1 class="fz-55 mt-30">Checkout</h1>
            </div>
          </div>
        </div>
        <div class="row mt-30">
          <div class="contact col-md-7">
            <form novalidate @submit.prevent="sendData">
              <div class="controls row">
                <div class="col-lg-12 text-center mb-30">
                  <h3 class="fz-30 mt-30">Datos básicos</h3>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-30">
                    <label>Tu Nombre</label>
                    <input
                      type="text"
                      name="name"
                      v-model="nombre"
                      v-validate="'required'"
                      data-vv-as="Tu nombre"
                    />
                    <span class="text-danger">{{ errors.first("name") }}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-30">
                    <label>Empresa</label>
                    <input
                      type="text"
                      name="nombreEmpresa"
                      v-model="nombreEmpresa"
                      v-validate="'required'"
                      data-vv-as="Empresa"
                    />
                    <span class="text-danger">{{
                      errors.first("nombreEmpresa")
                    }}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-30">
                    <label>NIT</label>
                    <input
                      type="text"
                      name="nitEmpresa"
                      v-model="nitEmpresa"
                      v-validate="'required'"
                      data-vv-as="NIT"
                    />
                    <span class="text-danger">{{
                      errors.first("nitEmpresa")
                    }}</span>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-30">
                    <label>E-mail</label>
                    <input
                      type="email"
                      name="email"
                      v-model="email"
                      v-validate="'required'"
                      data-vv-as="Tu e-mail"
                    />
                    <span class="text-danger">{{ errors.first("email") }}</span>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-30">
                    <label>Teléfono</label>
                    <input
                      type="number"
                      name="telefono"
                      v-model="telefono"
                      v-validate="'required'"
                      data-vv-as="Teléfono"
                    />
                    <span class="text-danger">{{
                      errors.first("telefono")
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 text-center mb-30">
                  <h3 class="fz-30 mt-30">Datos de acceso</h3>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-30">
                    <label>Usuario</label>
                    <input
                      type="text"
                      name="usuario"
                      v-model="usuario"
                      v-validate="'required'"
                      data-vv-as="Usuario"
                    />
                    <span class="text-danger">{{
                      errors.first("usuario")
                    }}</span>
                  </div>
                </div>

                <div class="col-lg-6"></div>

                <div class="col-lg-6">
                  <div class="form-group mb-30">
                    <label>Clave</label>
                    <input
                      type="password"
                      name="clave"
                      v-model="clave"
                      v-validate="'required'"
                      data-vv-as="Clave"
                    />
                    <span class="text-danger">{{ errors.first("clave") }}</span>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-30">
                    <label>Repetir Clave</label>
                    <input
                      type="password"
                      name="repetirClave"
                      v-model="repetirClave"
                      v-validate="'required'"
                      data-vv-as="Repetir Clave"
                      @blur="validaClave()"
                    />
                    <span class="text-danger">{{
                      errors.first("repetirClave")
                    }}</span>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="mt-20">
                    <button type="button" @click="sendData()">
                      <span class="text"
                        >Continuar con el pago
                        <b>${{ formatPrecio(precioSelected.precio) }}</b></span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="price col-md-5">
            <div class="col-lg-12 text-center mb-30">
              <h3 class="fz-30 mt-30">Resumen de la compra</h3>
            </div>

            <div class="item md-mb50">
              <h6 class="type">{{ plan.nombre }}</h6>
              <div class="content d-flex align-items-center">
                <div class="feat" v-html="plan.caracteristicas"></div>
              </div>
              <p class="text-center mt-3" v-html="plan.introduccion"></p>
              <h5 class="text-center mt-30">Seleccione el precio a pagar</h5>
              <div class="amount" role="group">
                <div class="row d-flex justify-content-center">
                  <div
                    v-for="(precioPlan, indexPrecio) in plan.precio"
                    class="col-auto main-color fz-15 p-2"
                    :key="indexPrecio"
                  >
                    <input
                      type="radio"
                      class="btn-check"
                      name="btnradio"
                      :id="'btnRadioPlan' + indexPrecio"
                      autocomplete="off"
                      :value="precioPlan"
                      v-model="precioSelected"
                      :checked="indexPrecio == 0 ? true : false"
                    />
                    <label
                      class="btn btn-outline-price"
                      :for="'btnRadioPlan' + indexPrecio"
                      >${{ formatPrecio(precioPlan.precio) }}
                      <small>/ {{ precioPlan.tiempoPlanControl }}</small></label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
    
<script>
import Swal from "sweetalert2";
export default {
  name: "CheckoutView",
  props: ["id"],
  data() {
    return {
      plan: false,
      nombre: "",
      nombreEmpresa: "",
      nitEmpresa: "",
      email: "",
      telefono: "",
      usuario: "",
      clave: "",
      repetirClave: "",
      precioSelected: false,
    };
  },
  created() {
    this.getPlan();
  },
  methods: {
    getPlan() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "planes/checkout/" + this.id)
        .then((response) => {
          if (!response.data.error) {
            this.plan = response.data.datos;
            this.precioSelected =
              this.plan.precio.length > 0 ? this.plan.precio[0] : false;
          } else {
            Swal.fire({
              title: "Error!",
              text: response.data.error,
              icon: "error",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "Oops error inesperado",
            icon: "error",
          });
        });
    },
    validaClave() {
      if (this.clave != this.repetirClave) {
        Swal.fire({
          title: "Que Mal!",
          text: "Las claves no coinciden",
          icon: "error",
        });
        return false;
      } else {
        return true;
      }
    },
    formatPrecio(precio) {
      if (precio >= 0) {
        return new Intl.NumberFormat().format(precio);
      } else {
        return " -";
      }
    },
    sendData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.plan) {
            if (this.precioSelected) {
              if (this.validaClave()) {
                this.axios
                  .post(
                    process.env.VUE_APP_API_URL + "planes/checkout/registro",
                    {
                      idPlan: this.plan.idPlan,
                      idPlanPrecio: this.precioSelected.idPlanPrecio,
                      nombre: this.nombre,
                      nombreEmpresa: this.nombreEmpresa,
                      nitEmpresa: this.nitEmpresa,
                      email: this.email,
                      telefono: this.telefono,
                      usuario: this.usuario,
                      clave: this.clave,
                    }
                  )
                  .then((response) => {
                    if (!response.data.error) {
                      handlerEpayco.open({
                        //Parametros compra (obligatorio)
                        name: "Pago inicial - " + this.plan.nombre,
                        description:
                          "Pago inicial web WDIShop - " +
                          this.precioSelected.tiempoPlanControl,
                        invoice: response.data.idCarrito,
                        currency: "cop",
                        amount: this.precioSelected.precio,
                        tax_base: "0",
                        tax: "0",
                        tax_ico: "0",
                        country: "co",
                        lang: "es",

                        //Onpage="false" - Standard="true"
                        external: "false",

                        //Atributos opcionales
                        extra1: response.data.datos,
                        confirmation:
                          process.env.VUE_APP_API_URL +
                          "planes/checkout/confirmation",
                        response: this.urlResponsePayment,
                      });
                    } else {
                      Swal.fire({
                        title: "Error!",
                        text: response.data.error,
                        icon: "error",
                      });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      title: "Error!",
                      text: "Oops error inesperado",
                      icon: "error",
                    });
                  });
              }
            } else {
              Swal.fire({
                title: "Que Mal!",
                text: "Por favor selecciona el precio a pagar",
                icon: "error",
              });
            }
          } else {
            Swal.fire({
              title: "Que Mal!",
              text: "No se encontró el plan",
              icon: "error",
            });
          }
        } else {
          Swal.fire({
            title: "Que Mal!",
            text: "Hay errores en el formulario",
            icon: "error",
          });
        }
      });
    },
  },
  computed: {
    urlStorage() {
      return process.env.VUE_APP_URL_STORAGE;
    },
    urlResponsePayment() {
      return (
        window.location.origin +
        this.$router.resolve({ name: "checkoutResponse" }).href
      );
    },
  },
};
</script>