<template>
  <footer>
    <div class="container pb-30 pt-30 bord-thin-top">
      <div class="row">
        <ul
          v-if="empresa"
          class="social d-flex justify-content-center rest mb-15"
        >
          <li v-if="empresa.whatsapp">
            <a
              :href="'https://api.whatsapp.com/send?phone=' + empresa.whatsapp"
              target="_blank"
            >
              <i class="fab fa-whatsapp"></i>
            </a>
          </li>
          <li v-if="empresa.instagram">
            <a :href="empresa.instagram" target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
          </li>
          <li v-if="empresa.facebook">
            <a :href="empresa.facebook" target="_blank">
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
          <li v-if="empresa.youtube">
            <a :href="empresa.youtube" target="_blank">
              <i class="fab fa-youtube"></i>
            </a>
          </li>
          <li v-if="empresa.tiktok">
            <a :href="empresa.tiktok" target="_blank">
              <img
                src="imgs/tiktok.png"
                alt="logo-tiktok"
                style="width: 21px; margin-top: -5px; filter: invert(1)"
              />
            </a>
          </li>
          <li v-if="empresa.twitter">
            <a :href="empresa.twitter" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li v-if="empresa.linkedin">
            <a :href="empresa.linkedin" target="_blank">
              <i class="fab fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <p class="fz-13">
              <router-link
                :to="{
                  name: 'politicaPrivacidad',
                }"
                class="underline ls1 main-color"
                >Política de Privacidad</router-link
              >
              |
              <router-link
                :to="{
                  name: 'terminosCondiciones',
                }"
                class="underline ls1 main-color"
                >Términos y Condiciones</router-link
              >
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <p class="fz-13">
              © 2023 WDI Shop es un producto de
              <span class="underline main-color">Web Design Innovation</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBase",
  data() {
    return {};
  },
  computed: {
    empresa() {
      return this.$store.getters.getEmpresa;
    },
  },
};
</script>