<template>
  <section class="header-crev bord-thin-bottom" data-scroll-index="0">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 valign">
          <div class="content">
            <h1>
              Controla, organiza <br />
              <span class="main-color">y crece</span>
            </h1>
            <div class="text mt-30">
              <p>
                Nuestro software abarca todo lo que necesitas: ventas,
                inventarios, informes, pagos y más.
              </p>
            </div>
            <div class="mt-50">
              <router-link
                :to="{
                  name: 'home',
                }"
                class="butn butn-md butn-bg bg-white text-dark radius-5"
                data-scroll-nav="2"
                ><span>Ver planes</span></router-link
              >
              <router-link
                :to="{
                  name: 'home',
                }"
                class="butn butn-md butn-bord radius-5 ml-15"
                data-scroll-nav="5"
                ><span>Contactanos</span></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="img-author">
            <img src="imgs/slider1.png" alt="" />

            <!-- <div class="box-shadwo">
              <div class="d-flex align-items-center">
                <h2>8</h2>
                <h6>
                  Years of <br />
                  Experiences
                </h6>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SliderBase",
};
</script>

<style>
</style>