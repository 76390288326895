var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-chang navbar-expand-lg"},[_c('div',{staticClass:"container position-re"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-2 col-6 order1"},[_c('router-link',{staticClass:"logo",attrs:{"to":{
            name: 'home',
          },"data-scroll-nav":"0"}},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":"Logo WDI"}})])],1),_c('div',{staticClass:"col-lg-7 order3"},[_c('div',{staticClass:"bg"},[_c('div',{staticClass:"full-width"},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                    name: 'home',
                  },"data-scroll-nav":"0"}},[_c('span',{staticClass:"rolling-text"},[_vm._v("Home")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                    name: 'home',
                  },"data-scroll-nav":"1"}},[_c('span',{staticClass:"rolling-text"},[_vm._v("Módulos")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                    name: 'home',
                  },"data-scroll-nav":"2"}},[_c('span',{staticClass:"rolling-text"},[_vm._v("Planes")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                    name: 'home',
                  },"data-scroll-nav":"4"}},[_c('span',{staticClass:"rolling-text"},[_vm._v("Blog")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                    name: 'home',
                  },"data-scroll-nav":"5"}},[_c('span',{staticClass:"rolling-text"},[_vm._v("Nosotros")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                    name: 'home',
                  },"data-scroll-nav":"6"}},[_c('span',{staticClass:"rolling-text"},[_vm._v("Contactanos")])])],1)])])])]),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-6 order2"},[_c('div',{staticClass:"bord d-flex justify-content-end"},[_c('button',{staticClass:"btn-login ml-5",attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":"#accesoModal"}},[_c('span',{staticClass:"text"},[_vm._v("Acceso")])]),_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button"}},[_c('span',{staticClass:"icon-bar"},[_c('i',{staticClass:"fas fa-bars"})])])])])
}]

export { render, staticRenderFns }